import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const ReviewGuidelinesPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>
            Review Guidelines | AI Product Reviews
          </SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              At AI Product Reviews, we are committed to assisting users to
              discover the most effective AI tools on the market to meet their
              specific needs. We understand that deciding which AI tool to
              choose can be overwhelming. Therefore, we have established a
              rigorous review process to provide you with candid and unbiased
              evaluations.
            </p>
            <p>
              We are dedicated to ethical review practices. Discover our modern
              slavery statement or read on to explore our commitment to
              responsible AI guidance.
            </p>
            <h2>Our Review Process</h2>
            <p>
              Before we feature an AI tool on our site, we consider the
              following factors and ensure a rigorous review process takes
              place.
            </p>
            <h3>In-Depth Assessment of AI Tools</h3>
            <p>
              We thoroughly examine an array of AI tools, testing each one
              individually to gain comprehensive insights. This process enables
              us to evaluate their performance, features, and functionalities in
              real-world scenarios. We also conduct comparative analyses with
              similar tools available in the market.
            </p>
            <h3>Standardized Criteria for Evaluation</h3>
            <p>
              To ensure consistency and fairness in our reviews, we employ a set
              of standardized criteria. These criteria cover vital aspects such
              as user-friendliness, cost-effectiveness, versatility, and
              scalability. This allows us to objectively assess and compare AI
              tools across the board.
            </p>
            <h3>Fact-Checking</h3>
            <p>
              We prioritize accuracy and credibility in our reviews. Therefore,
              our dedicated editors meticulously fact-check all articles before
              publication, upholding the integrity of the information we
              provide.
            </p>
            <h3>Anonymous Testing</h3>
            <p>
              We use AI tools anonymously. This enables us to experience the
              entire process just as a user would, from purchase to delivery of
              services. We assess the pricing structure, ease of use, and
              customer service experience. This approach ensures an authentic
              and unbiased evaluation.
            </p>
            <h3>Extensive User Review Analysis</h3>
            <p>
              In addition to our hands-on experience, we meticulously analyze
              both positive and negative user reviews from across the internet.
              We seek to understand the experiences of users to ensure their
              insights are accurately represented in our content.
            </p>
            <h3>Analyzing Marketing Claims</h3>
            <p>
              We also scrutinize the promotional content of AI tools, debunking
              any false or extravagant claims. Our goal is to provide you with
              accurate and reliable information about what you're paying for.
            </p>
            <h3>Specific Rating Criteria [remove if not applicable]</h3>
            <p>
              We employ a 5-star rating system to provide clear and concise
              evaluations:
            </p>
            <ul>
              <li>Five stars: Editor&rsquo;s choice</li>
              <li>Four stars: An excellent choice</li>
              <li>Three stars: Meets some of our standards</li>
              <li>Two stars: Doesn&rsquo;t meet our standards</li>
              <li>One star: Not recommended</li>
            </ul>
            <p>
              This system allows you to quickly gauge the overall quality and
              suitability of each AI tool.
            </p>
            <h3>Continuous Updates</h3>
            <p>
              Our team stays up-to-date with the latest advancements in AI
              technology. We regularly update our guides to reflect the most
              current industry trends.
            </p>
            <h3>What We Never Do</h3>
            <ul>
              <li>
                We never accept sponsorship fees from AI brands to prioritize
                their tools.
              </li>
              <li>
                We never post reviews without exhaustive research and analysis.
              </li>
            </ul>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ReviewGuidelinesPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Review Guidelines | AI Product Reviews"}
      description={
        "Our dedication to ethical AI practices. Discover our modern slave statement and responsible AI commitment."
      }
      pathname={location.pathname}
    />
  );
};
